

body {
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 13px;
    color:#333
}

p {
    padding: 10px;
}

#taskview {
    width: 100%;
    min-width: 1000px;
    max-width: 2000px;
    margin: 0 auto;
}

#contentliquid {
    float: left;
    width: 100%;
}

#content {
    background: #FFFFFF;
    margin-right: 500px;
    padding-right: 3%;
    padding-left: 3%;
}

#rightcolumn {
    /* background: #CC33FF; */
    width: 500px;
    margin-left: -500px;
    float: left;
}

#leftcolumn {
    width: 500px;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 10px; 
    float: left;
}

#status-container {
    /* background: khaki; */
    color: black;
    padding: 10px; 
}

#view-interface {
    margin: 10px;
}

#title {
    margin-bottom: 10px; 
}

.ant-btn-dangerous {
    margin-top: 50px;
}

.narrative-preview {
    text-align: left;
}

.task-title-container {
    height: 40px;
    background-color: #8bc34a;
    border: 3px solid lightgoldenrodyellow;
}

.link-to-input {
    text-decoration: underline;
    cursor: pointer;
}

.no-display{display:none !important}.no-transition,.no-transition *{transition:none !important}.unselectable{-webkit-user-select:none !important;-moz-user-select:none !important;-ms-user-select:none !important;user-select:none !important;cursor:not-allowed}.hidden-layer,.hidden-layer *{opacity:0;z-index:1;cursor:crosshair !important}.visible-layer,.visible-layer *{opacity:1;z-index:-1;pointer-events:none;transition:all .7s}.visible-layer .lmdd-mirror.gf-transition{transition:all .3s !important}.visible-layer .lmdd-mirror{z-index:1;position:absolute;margin:0;pointer-events:none;transition:top 00s,left 00s !important;opacity:.5}.lmdd-shadow{opacity:.5}.lmdd-hidden{visibility:hidden}
.list-group-item {
    padding: 3px;
    margin: 5px;
    color: black;
    font-size: 15px;
    background-color: white;
    border: 3px solid lightgoldenrodyellow;
    margin-bottom:3px;
  }

.list-group-item > div {
    display: inline-block;
}

.drag-handle {
    cursor: move;
}

  .sub-task{
    
    background-color: #8bc34a;
  } 

  .list-group{
    padding:5px;
    background-color: #E8F5E9;
    /* margin-top: 50px; */
  }
  #main {
    width: 600px;
    margin: 0 auto;
    text-align: left;
  }
  
  ul {
    list-style: none;
  }
  
  /* ul>li:hover {
    background-color: #F5F5F5;
  } */
  
  .form-inline .form-control {
    width: 89%;
    margin-left: 10px;
  }
  
  .done {
    color: red;
    text-decoration: line-through;
  }
  
  .icon {
    margin: 6px 10px 6px 0;
  }
  
  .handle{
   margin-right:15px;
  }

  .popup {
    float:left;
    outline: none;
    width: 100%;
    height: 100%;
  }
  
  .popup strong {
    line-height: 30px;
  }
  
  .popup:hover {
    text-decoration: none;
  }
  
  .popup span {
    z-index: 10;
    display: none;
    /* float:right; */
    /* padding: 14px 20px; */
    /* margin-top: -30px; */
    margin-left: 2px;
    /* width: 300px; */
    /* line-height: 16px; */
  }
  
  .popup:hover span{
    display: inline;
    position: absolute;
    color: #111;
    border: 1px solid #DCA;
    background: #fffAF0;
  }
  
  /*CSS3 extras*/
  
  .popup span {
    border-radius: 4px;
    box-shadow: 5px 5px 8px #CCC;
  }
  
  .ant-form-item-with-help {
    margin-bottom: 24px;
    }

#title::placeholder {
    color: grey;
    opacity: 1; /* Firefox */
}

#title:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: grey;
}

#title::-ms-input-placeholder { /* Microsoft Edge */
    color: grey;
}