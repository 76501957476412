#tasklistview {
    /* min-height: 100vh; */
    margin-left: 9%;
    margin-right: 9%;
}

.statusLabel:hover {
    cursor: pointer;
}

#tasklistview table td:nth-child(3), #tasklistview table th:nth-child(3){
    min-width: 110px;
}

.hidden {
    display: none !important;
}

.create-request-btn {
    margin: 50px;
}

.expandBtn:focus {
    color: rgba(0, 0, 0, 0.85); 
    border-color: #d9d9d9; 
}

.statusLabel, .statusLabel-others {
    display: inline-table; 
    margin-left: 10px;
    margin-right: 10px; 
    padding: 0px;
}

.nameLabel {
    display: inline-block;
    padding: 5px; 
    background-color: lightgray;
    font-size: 11px;
}

.statLabel {
    background-size:100%;
    /* background-color: lightgray; */
    display: inline-block;
    padding: 5px; 
    font-size: 11px;
}