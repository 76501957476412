.app {
  text-align: center;
}

table {
  width: 90%; 
  border-collapse: collapse;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

th, td {
  text-align: left;
  padding: 0.7%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Chat interface */


main {
  padding: 10px;
  height: 30vh;
  overflow-y: scroll;
  /* display: flex; */
  flex-direction: column;
}

/* form {
  height: 10vh;
  position: fixed;
  bottom: 0;
  background-color: rgb(24, 23, 23);
  width: 100%;
  max-width: 728px;
  display: flex;
  font-size: 1.5rem;
} */


ul, li {
  text-align: left;
  list-style: none;
}

p {
  max-width: 500px;
  margin-bottom: 12px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  text-align: center;
}  

.message {
  display: flex;
  align-items: center;
}


.sent {
  flex-direction: row-reverse;
}

.sent p {
  color: white;
  background: #0b93f6;
  align-self: flex-end;
}
.received p {
  background: #e5e5ea;
  color: black;
}

.chat {
  border-radius: 10px; 
  padding-bottom: 20px;
}

.landing-page-menu {
  float: left; 
}

.landing-page {
  text-align: center;
  font-size: large;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 65px; 
}

.keywords {
  color: #d9a700;
}

.img-circle {
  border: 5px #FFFFFF solid;
  border-radius: 50%;
}
